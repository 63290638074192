import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Arizona Center for Rural Health received a two-year, $8 million award as part of the Arizona Department of Health Services ADHS-CDC National Initiative to Address COVID-19 Disparities among Populations at High Risk & Underserved, Including Racial & Ethnic Minority Populations and Rural Communities.`}</p>
    <p>{`You can learn more about the project here: `}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://crh.arizona.edu/programs/ahead-az",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://crh.arizona.edu/programs/ahead-az`}</a></li>
    </ul>
    <p>{`Our team will be developing a number of NLP-powered tools to support project goals.  These tools include ...`}</p>
    <ul>
      <li parentName="ul">{`A neural (embedding-based) search engine for question-answering over a custom knowledge base`}</li>
      <li parentName="ul">{`ASR models specialized for the medical domain`}</li>
      <li parentName="ul">{`Document labeling system to assist with personalized messaging`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      